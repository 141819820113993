import React from "react";
import "./PricingHero.css";

function PricingHero() {
  return (
    <>
      <section className="pricing_hero">
        <div className="pricing_txt">
          <h2>Pricing Minimal</h2>
        </div>
      </section>
    </>
  );
}

export default PricingHero;
