const apply = [
  {
    job_expertis: "React/ Laravel",
    job_name: "Web Developer",
    jobNature: "Full Time",
    Salary: "10k-15k",
    jobDes:
      " We are currently seeking a highly motivated individual to fill an I.T. This position will provide remote and onsite hands-on support to staff, vendors, and customers as well as, proactively identify issues.",
  },
  {
    job_expertis: " Laravel",
    job_name: "Web Developer",
    jobNature: "Intern",
    Salary: "5k-7k",
    jobDes:
      " We are currently seeking a highly motivated individual to fill an I.T. This position will provide remote and onsite hands-on support to staff, vendors, and customers as well as, proactively identify issues.",
  },
  {
    job_expertis: "React/ Laravel",
    job_name: "Web Developer",
    jobNature: "Full Time",
    Salary: "10k-15k",
    jobDes:
      " We are currently seeking a highly motivated individual to fill an I.T. This position will provide remote and onsite hands-on support to staff, vendors, and customers as well as, proactively identify issues.",
  },
  {
    job_expertis: "React/ Laravel",
    job_name: "Web Developer",
    jobNature: "Full Time",
    Salary: "10k-15k",
    jobDes:
      " We are currently seeking a highly motivated individual to fill an I.T. This position will provide remote and onsite hands-on support to staff, vendors, and customers as well as, proactively identify issues.",
  },
];
export default apply;
