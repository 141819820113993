import p1 from "../../Images/protfolio/prot_slider/p1.jpg";
import p2 from "../../Images/protfolio/prot_slider/p2.jpg";
import p3 from "../../Images/protfolio/prot_slider/p3.jpg";
import p4 from "../../Images/protfolio/prot_slider/p4.jpg";
import p5 from "../../Images/protfolio/prot_slider/p5.jpg";

const prot_slider = [
  {
    id: 1,
    img: p1,
  },
  {
    id: 2,
    img: p2,
  },
  {
    id: 3,
    img: p3,
  },
  {
    id: 4,
    img: p4,
  },
  {
    id: 5,
    img: p5,
  },
];

export default prot_slider;
