import React from "react";
import "./CareerHero.css";

function CareerHero() {
  return (
    <>
      <div className="career_banner">
        <div className="career_text">
          <h2>Career</h2>
        </div>
      </div>
    </>
  );
}

export default CareerHero;
